function add_validator_methods() {
$.validator.addMethod("emailExt", function(value, element, param) {
    return value.match(/^[a-zA-Z0-9_\.%\+\-]+@[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,}$/);
},'Please enter a valid email address');

}
function initialize_signup_form(ele_id) {
    add_validator_methods();
    var form_id = "#" + ele_id;
    $(form_id).validate({
        ignore: ":hidden",
        rules: {
            "client[email]": {email: true},
            "client[address]": {required: true},
            "client[currency]": {required: true},
            "client[payment_process]": {required: true},
            "client[phone_no]": {number: true},
            "project[client_id]": {required: true},
            "project[name]": {required: true},
            "project[database]": {required: true},
            "project[server]": {required: true},
            "payment[project_id]": {required: true},
            "payment[payment_date]": {required: true},
            "payment[member_id]": {required: true},
            "invoice[payment_id]": {required: true},
            "invoice[invoice_number]": {required: true},
            "invoice[month]": {required: true},
            "invoice[year]": {required: true},
            "invoice[member_id]": {required: true},
        },
        errorPlacement: function (error, element) {
            if (element.hasClass('phone-number')) {
                element.closest(".iti").parent().append(error);
            }
            else {
                element.closest("div").append(error);
            }
        },
        messages: {
            'user[password_confirmation]': {
                equalTo: "The passwords you entered do not match"
            }
        },
        onkeyup: false, //Only do validations when form focus changes to avoid exessive ASI calls
        submitHandler: function (form) {
            disable_and_submit(form_id, form, "false");
        }
    });
}

function disable_and_submit(form_id, form, ajax) {
    disable_submit_button(form_id);
    form.submit();
}

function disable_submit_button(form_id) {
    $(form_id).find("button").attr('disabled', 'disabled');
    // $(form_id).find("button").text("please_wait");
}

export default initialize_signup_form;
