import HSUnfold from "./hs-unfold";
import HSTogglePassword from "./hs-toggle-password.min";
import HSPWStrength from "./pwstrength-bootstrap.min";
import HSDatatables from "./jquery.dataTables.min";
import Select2 from "./select2.full.min";

document.addEventListener("turbolinks:load", function () {
    if ($("#client-form").length != 0) {
        initialize_signup_form("client-form");
    }
    if ($("#project-form").length != 0) {
        initialize_signup_form("project-form");
    }
    if ($("#payment-form").length != 0) {
        initialize_signup_form("payment-form");
    }
    if ($("#invoice-form").length != 0) {
        initialize_signup_form("invoice-form");
    }
    if ($("#invoice-detail").length != 0) {
        initialize_signup_form("invoice-detail");
    }

    $('.js-navbar-vertical-aside-toggle-invoker').click(function () {
        $('.js-navbar-vertical-aside-toggle-invoker i').tooltip('hide');
    });
    var sidebar = $('.js-navbar-vertical-aside').hsSideNav();

    $('.js-nav-tooltip-link').tooltip({boundary: 'window'})

    $(".js-nav-tooltip-link").on("show.bs.tooltip", function (e) {
        if (!$("body").hasClass("navbar-vertical-aside-mini-mode")) {
            return false;
        }
    });

    $(".set-date").flatpickr({
        maxDate: 'today',
        dateFormat: "Y-m-d",
    });

    $('.js-hs-unfold-invoker').each(function () {
        var unfold = new HSUnfold($(this)).init();
    });

    $('.js-toggle-password').each(function () {
        new HSTogglePassword(this).init()
    });

    $('.js-pwstrength').each(function () {
        var pwstrength = $.HSCore.components.HSPWStrength.init($(this));
    });

    $('.js-select2-custom').each(function () {
        var select2 = $.HSCore.components.HSSelect2.init($(this));
    });

    if ($('#datatable').length !== 0) {
        var datatable = $.HSCore.components.HSDatatables.init($('#datatable'), {
            dom: 'Bfrtip',

        }).on('draw.dt', function () {
            $('#datatable .js-hs-unfold-invoker').each(function () {
                new HSUnfold($(this)).init();
            });
            $('[data-toggle="tooltip"]').tooltip();
        });
    }

    $('.toggle-switch-input').change(function (e) {
        datatable.columns($(this).data('columns')).visible(e.target.checked)
    })

    if ($("#datatable").hasClass("payment-table")) {
        datatable.columns(2).visible(false)
        datatable.columns(3).visible(false)
        datatable.columns(5).visible(false)
        datatable.columns(6).visible(false)
        datatable.columns(7).visible(false)
        datatable.columns(8).visible(false)
    }

})
