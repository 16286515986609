// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
import * as ActiveStorage from "@rails/activestorage"
require("channels")

require("jquery")
require("jquery-validation");
require("../theme/jquery-migrate.min");
require("../theme/bootstrap.bundle");
require("flatpickr");

require("../theme/hs-navbar-vertical-aside.min");
require("../theme/theme.min")
require("../custom/custom")
require("../theme/custom")

import Swal from 'sweetalert2'
window.Swal = Swal;

import intlTelInput from "intl-tel-input";
window.intlTelInput = intlTelInput;

import * as FilePond from "filepond";
window.FilePond = FilePond;

import FilePondPluginFileEncode from "filepond-plugin-file-encode";
window.FilePondPluginFileEncode = FilePondPluginFileEncode;

import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
window.FilePondPluginFileValidateType = FilePondPluginFileValidateType;

import FilePondPluginImagePreview from "filepond-plugin-image-preview";
window.FilePondPluginImagePreview = FilePondPluginImagePreview;

import FilePondPluginFilePoster from "filepond-plugin-file-poster";
window.FilePondPluginFilePoster = FilePondPluginFilePoster;

import FilePondPluginImageCrop from "filepond-plugin-image-crop";
window.FilePondPluginImageCrop = FilePondPluginImageCrop;


FilePond.registerPlugin(
    FilePondPluginFileEncode,
    FilePondPluginFileValidateType,
    FilePondPluginImagePreview,
    FilePondPluginFilePoster,
    FilePondPluginImageCrop
);

import 'flatpickr';
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
global.$ = jQuery;

import "../stylesheets/application.scss"

import initialize_signup_form from '../theme/kassi'
window.initialize_signup_form = initialize_signup_form;
