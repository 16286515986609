import intlTelInpututility from "intl-tel-input/build/js/utils";

$(document).on('turbolinks:load', function () {

    if ($("#client_phone_no").length != 0) {
        PhoneDisplay(document.querySelector("#client_phone_no"), "phone_no")
    }
    if ($("#employee_phone_no").length != 0) {
        PhoneDisplay(document.querySelector("#employee_phone_no"), "phone_no")
    }
    if ($("#employee_family_phone_no").length != 0) {
        PhoneDisplay(document.querySelector("#employee_family_phone_no"), "family_phone_no")
    }

    init_filepond_profile_pic();

    if($('.select-pdf-btn').length != 0){
        $('.select-pdf-btn').on('click', function(e){
        e.preventDefault();
            var ids = []
            $("input:checkbox[name=invoice-checkbox]:checked").each(function(){
                ids.push($(this).val());
            });
            window.location = $(this).attr('href') + "?invoice_ids=" + ids
        });
        $('#datatableWithCheckboxSelectAll').on('click', function(e){
            $("input:checkbox[name=invoice-checkbox]").prop('checked', $(this).prop("checked"))
        });
    }

    if($('.select-excel-btn').length != 0){
        $('.select-excel-btn').on('click', function(e){
            e.preventDefault();
            var ids = []
            $("input:checkbox[name=invoice-checkbox]:checked").each(function(){
                ids.push($(this).val());
            });
            window.location = $(this).attr('href') + "?invoice_ids=" + ids
        });
    }
    if($('#delete-invoice-form').length != 0){
        $('.select-delete-inv-btn').on('click', function(e){
            e.preventDefault();
            var ids = []
            $("input:checkbox[name=invoice-checkbox]:checked").each(function(){
                ids.push($(this).val());
            });
            $(".selected_invoice_ids").val(JSON.stringify(ids))
            $('#delete-invoice-form').submit();
        });
    }

});

function PhoneDisplay(input, field) {
// Set Phone Number Country Code Validations
    var errorMsg = document.querySelector(".error-msg")

    if (input) {
        // here, the index maps to the error code returned from getValidationError - see readme
        var errorMap = ["Invalid number", "Invalid country code", "Too short", "Too long", "Invalid number"];

        // initialise plugin
        var iti = window.intlTelInput(input, {
            initialCountry: "in",
            separateDialCode: true,
            hiddenInput: field,
            utilsScript: intlTelInpututility,
            formatOnDisplay: false
        });
        var reset = function () {
            input.classList.remove("error");
            input.closest('.form-group').getElementsByClassName('error-msg')[0].innerHTML = ""
        };


        // on blur: validate
        input.addEventListener('blur', function () {
            reset();
            if (input.value.trim()) {
                if (iti.isValidNumber()) {
                    $('.phone-number').closest("form").find('button[type="submit"]').attr("disabled", false);
                } else {
                    input.classList.add("error");
                    var errorCode = iti.getValidationError();
                    if (typeof errorMap[errorCode] !== "undefined") {
                        input.closest('.form-group').getElementsByClassName('error-msg')[0].innerHTML = errorMap[errorCode];
                        $('.phone-number').closest("form").find('button[type="submit"]').attr("disabled", true);
                    }
                }
            }
        });

        input.addEventListener("countrychange", function (e, countryData) {
        });
        // on keyup / change flag: reset
        input.addEventListener('change', reset);
        input.addEventListener('keyup', reset);
    }
}

export function init_filepond_profile_pic() {
    $(".filepond-profile-pic").each(function () {
        if ($(this).data("name")) {
            createprofilePoster(
                this,
                $(this).data("name"),
                $(this).data("size"),
                $(this).data("type"),
                $(this).data("url"),
                $(this).data("remove-url"),
                $(this).data("id")
            );
        } else {
            var label = $(this).data('label-icon') ? '<p style="font-size: 5rem">' +$(this).data('label-icon')+ '</p>' : '<i class="fas fa-image fa-5x" style="font-size: 5rem"></i>'
            FilePond.create(
                document.querySelector('.profile-image'),
                {
                    labelIdle: label,
                    imagePreviewHeight: 150,
                    imageCropAspectRatio: '1:1',
                    imageResizeTargetWidth: 200,
                    imageResizeTargetHeight: 200,
                    stylePanelLayout: 'compact circle',
                    styleLoadIndicatorPosition: 'center bottom',
                    styleButtonRemoveItemPosition: 'center bottom'
                }
            );
        }
    });
}

function createprofilePoster(input, name, size, type, url, delete_url, item_id) {
    FilePond.create(input, {
        name: "filepond",
        imagePreviewHeight: 150,
        imageCropAspectRatio: '1:1',
        imageResizeTargetWidth: 200,
        imageResizeTargetHeight: 200,
        stylePanelLayout: 'compact circle',
        styleLoadIndicatorPosition: 'center bottom',
        styleButtonRemoveItemPosition: 'center bottom',
        files: [{
            options: {
                file: {
                    name: name,
                    size: size,
                    type: type
                },
                metadata: {
                    poster: url
                }
            }
        }]
    });
    $(".filepond-profile-pic").on("FilePond:removefile", function (e) {
        var id = item_id;
        if (id) {
            $.ajax({
                url: delete_url,
                type: "POST",
                datatype: "Script",
                data: {id: id},
                success: function (result) {
                }
            });
        }
    });
}
